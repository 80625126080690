<template>
  <div data-testid="basal-once-weekly-section">
    <v-row>
      <v-col> Prescribe initial <strong>basal</strong> dose</v-col>
    </v-row>
    <v-row>
      <v-col cols="12" sm="3">
        <DoseInput
          :value="prescription.basal_once_weekly.value"
          @input="onInput($event, 'basal_once_weekly')"
          @change="onChange($event, 'PRESCRIBE_DOSE:WEEKLY_DOSE_VALUE')"
          :validation-rules="getValidationRules('basal_once_weekly')"
          :dosing-display-unit="dosingDisplayUnit('basal_once_weekly')"
          :default-initial-dose="getDefaultInitialDose('basal_once_weekly')"
          data-testid="input-basal-once-weekly"
          :can-deactivate="false"
          :required-text="'Prescribe initial weekly dose is required'"
        >
          Initial <strong>weekly</strong> dose
        </DoseInput>
      </v-col>
      <v-col sm="4">
        <FormLabel>
          Select a <strong>dosing day</strong> for the patient
        </FormLabel>
        <v-select
          :value="dosingDay"
          placeholder="Choose a day"
          :items="dosingDayOptions"
          :rules="dosingDayRules"
          outlined
          @change="onChangeWeeklyDosingDay($event)"
          data-testid="input-basal-once-weekly-dosing-day"
          class="rounded-lg"
          :menu-props="{
            maxHeight: 380,
          }"
        >
        </v-select>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import DoseInput from './_DoseInput'
import FormLabel from '../shared/FormLabel'
import initialPrescriptionMixin from './initialPrescriptionMixin'

export default {
  name: 'OnceWeeklyInitialDose',
  mixins: [initialPrescriptionMixin],
  components: { FormLabel, DoseInput },
  data() {
    return {
      dosingDayRules: [v => !!v || 'Please select a dosing day'],
    }
  },
}
</script>
